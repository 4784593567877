import { createTheme } from '@mui/material'

const theme = createTheme({
  palette: {
    primary: {
      main: '#8db600',
    },
    secondary: {
      main: '#616161',
    },
    error: {
      main: '#d32f2f',
    },
    warning: {
      main: '#ed6c02',
    },
    info: {
      main: '#0288d1',
    },
    success: {
      main: '#2e7d32',
    },
  },
  typography: {
    fontFamily: 'Roboto, "Noto Sans HK", Arial, Helvetica, sans-serif',
  },
})

export default theme
