import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

export interface SeoProps {
  title?: string
  description?: string
}

const Seo: React.FC<SeoProps> = ({ title, description }) => {
  const { site } = useStaticQuery(query)
  const { defaultTitle, titleTemplate, defaultDescription, siteUrl } = site.siteMetadata

  return (
    <Helmet title={title ?? defaultTitle} titleTemplate={titleTemplate}>
      <meta name="description" content={description ?? defaultDescription} />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:title" content={title ?? defaultTitle} />
      <meta property="og:description" content={description ?? defaultDescription} />
    </Helmet>
  )
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
      }
    }
  }
`

export default Seo
