import { WrapRootElementBrowserArgs } from 'gatsby'
import React from 'react'
import './app.css'
import Seo from './packages/seo/seo'

const App = ({ element }: WrapRootElementBrowserArgs) => {
  return (
    <>
      <Seo />
      {element}
    </>
  )
}

export default App
